<template>
  <div class="quota-multiple">
    <div v-if="!isMobile" class="quota-day__time">
      <div class="desktopWrapper">
        <span class="quota-day__time-text">с</span>

        <span class="quota-day__time-val">
          {{ getCurrentTime(getTimeFrom) }}
        </span>

        <span class="quota-day__time-text">по</span>

        <span class="quota-day__time-val">
          {{ getCurrentTime(getTimeTo) }}
          <span
            v-if="checkLongDay.isLong"
            class="quota-day__time-val quota-day__time-val--long"
          >
            /&nbsp;{{ checkLongDay.finishDate }}
          </span>
        </span>
      </div>
    </div>

    <div class="quota-multiple__content">
      <div
        v-for="(item, index) in quota"
        :key="item.id"
        class="quota-multiple__item"
      >
        <QuotaContent
          :quota="item"
          @emitClose="handleClose"
          @updateBeforeDelete="$emit('updateBeforeDeleted')"
        />
        <div v-if="isMobile" class="quota-multiple__item-buttons">
          <div class="mobileWrapper">
            <span class="quota-day__time-val">
              {{ getCurrentTime(getTimeFrom) }}
            </span>

            <span class="quota-day__title-slash">-</span>

            <span class="quota-day__time-val">
              {{ getCurrentTime(getTimeTo) }}
              <span v-if="checkLongDay" class="quota-day__title-slash">
                /
              </span>
              <span
                v-if="checkLongDay.isLong"
                class="quota-day__time-val quota-day__time-val--long"
              >
                {{ checkLongDay.finishDate }}
              </span>
            </span>
          </div>

          <div class="quota-day__icons">
            <IconPenMobile
              v-if="!isSupport && !isViewer"
              @onClick="handleEditCulture(item)"
            />
            <IconTrashMobile
              v-if="!isExporter && !isSupport && !isViewer"
              @onClick="handleDeleteCulture(item)"
            />
            <div class="details-show-icon">
              <Icon
                name="ArrowDownIcon"
                width="22px"
                height="22px"
                :class="['arrow', { rotated: isOpen[index] }]"
                @onClick="isOpen.splice(index, 1, !isOpen[index])"
              />
            </div>
          </div>
        </div>
        <el-collapse-transition>
          <QuotaShared
            v-show="isOpen[index] && isMobile"
            :quota="item"
            class="mobile-detail"
          />
        </el-collapse-transition>
      </div>
    </div>
  </div>
</template>

<script>
import { DATE_FORMAT, GET_DAY, GET_TIME } from '@/constants/date'
import { DIALOG_ADD_QUOTA, HOME_DELETE_QUOTA } from '@/constants/dialogs'
import { GET_IS_MOBILE } from '@/store/actions'
import { getTzTime } from '@/core'
import { mapGetters } from 'vuex'
import Icon from '@/UI/icon/Icon.vue'
import IconPenMobile from '@/UI/icon/IconTrashMobile'
import IconTrashMobile from '@/UI/icon/IconPenMobile'
import QuotaContent from '@/views/home/components/quota-day/components/QuotaContent'
import QuotaShared from '@/views/home/components/quota-day/components/QuotaShared.vue'

export default {
  name: 'QuotaMultiple',
  components: {
    QuotaShared,
    Icon,
    QuotaContent,
    IconTrashMobile,
    IconPenMobile,
  },
  props: {
    quota: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isOpen: [],
    }
  },
  computed: {
    ...mapGetters({
      isMobile: GET_IS_MOBILE,
    }),
    getTimeFrom() {
      return this.quota[0].time_from
    },
    getTimeTo() {
      return this.quota[0].time_to
    },
    checkLongDay() {
      return {
        isLong:
          getTzTime(this.getTimeFrom, DATE_FORMAT) !==
          getTzTime(this.getTimeTo, DATE_FORMAT),
        finishDate: getTzTime(this.getTimeTo, GET_DAY),
      }
    },
  },
  mounted() {
    this.isOpen = Array(this.quota.length).fill(false)
  },
  methods: {
    getCurrentTime(date) {
      return getTzTime(date, GET_TIME)
    },
    handleClose() {
      this.editCulture = false
      this.deleteCulture = false
    },
    handleEditCulture(quota) {
      this.setDialog({
        name: DIALOG_ADD_QUOTA,
        data: { ...quota, isEdit: true },
        visible: true,
      })
      this.$emit('emitClose')
    },
    handleDelete(data) {
      this.setDialog({ name: HOME_DELETE_QUOTA, visible: true, data })
    },
    handleDeleteCulture(quota) {
      this.handleDelete({
        id: quota.id,
        count: quota.count,
      })
      this.$emit('updateBeforeDelete')
      this.$emit('emitClose')
    },
  },
}
</script>

<style lang="sass">
.quota-multiple
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 20px
  &:last-child
    margin-bottom: 0

  &__content
    flex: 1 0 71%

  &__item
    margin-bottom: 10px

  .desktopWrapper
    display: flex

@media (max-width: 1200px)
  .quota-multiple
    flex-direction: column-reverse
    align-items: flex-start
    &__content
      flex: auto
      width: 100%
    &__item
      margin-bottom: 24px
    &__item:last-child
      margin-bottom: 0
    &__item-buttons
      display: flex
      justify-content: space-between
      margin-top: 12px
      .mobileWrapper
        display: flex
        align-items: center
        justify-content: center
        .quota-day__time-val
          background: #fff
          font-weight: 600
          border: none
          font-size: 16px
          margin: 0
          padding: 0
          &--long
            justify-content: flex-start
        .quota-day__title-slash
          margin: 0 4px
        .quota-day__time
          flex: auto
          align-items: center
          justify-content: space-between
          width: 100%
          margin: 22px 0 0
      .quota-day__icons
        display: flex
        align-items: center
        justify-content: space-between
        column-gap: 12px
        .details-show-icon
          display: flex
          justify-content: center
          align-items: center
          width: 32px
          height: 32px
          border-radius: 8px
          background: #f4f4f5
          border: 1px solid #E4E7ED
          .arrow
            transition: transform 0.3s ease
            &.rotated
              transform: rotate(180deg)
            &.iq-icon *
              fill: none
              stroke: $fontColor
    .mobile-detail
      width: 100%
      padding-bottom: 4px
</style>
